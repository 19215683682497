import React, { useCallback } from 'react';
import styled, { css } from 'styled-components';
import ProblemItem from './ProblemItem';
import {ProblemType} from '../App';
type Props = {
    problems: ProblemType[];
    targetId: number;
    addProblem: (id: number, targetId: number) => void;
    replaceProblem: (id: number, targetId: number) => void;
}

type ButtonGroupProps = {
    id: number;
    targetId: number;
    addProblem: (id: number, targetId: number) => void;
    replaceProblem: (id: number, targetId: number) => void;
}

const ButtonGroup: React.FunctionComponent<ButtonGroupProps> = ({ id, targetId, addProblem, replaceProblem }) => {
    const handleAddProblem = useCallback(() => {
        addProblem(id, targetId);
    }, [targetId])

    const handleReplaceProblem = useCallback(() => {
        replaceProblem(id, targetId);
    }, [targetId])
    return (
        <ButtonGroupBlock>
            <Button onClick={handleAddProblem}>추가</Button>
            <Button onClick={handleReplaceProblem}>교체</Button>
        </ButtonGroupBlock>
    )
}
const SimilarList: React.FunctionComponent<Props> = ({ problems, targetId, addProblem, replaceProblem }) => {
    return (
        <>
            {problems.map((item, index) => {
                return (
                    <ProblemItem
                        key={item.id}
                        index={index}
                        id={item.id}
                        problemType={item.problemType}
                        unitName={item.unitName}
                        problemURL={item.problemURL}
                        headerButtonGroupComponent={
                            <ButtonGroup
                                id={item.id}
                                targetId={targetId}
                                addProblem={addProblem}
                                replaceProblem={replaceProblem}
                            />
                        }
                    />
                )
            })}
        </>
    )
}

const ButtonGroupBlock = styled.div`
    display: flex;
    margin-right: 15px;
    >:first-child {
        margin-right: 8px;
    }
`;

const Button = styled.button<{ active?: boolean }>`
    width: 80px;
    height: 36px;
    font-size: 14px;
    font-weight: 700;
    color: #00ABFF;
    border: 1px solid #E0E0E0;
    border-radius: 3px;
    background: white;

    ${props => props.active &&
        css`
            border-color: #00ABFF;
            background: #00ABFF;
            color: white;
        `
    }
`;

export default React.memo(SimilarList);