import React, { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
// import ListWrapper from './components/ListWrapper';
import ProblemList from './components/ProblemList';
import SimliarList from './components/SimilarList';
import useStateWithLatest from './lib/useStateWithLatest';
import problems from './problems.json';
import similars from './similars.json';

export type ProblemType = {
  id: number;
  unitCode: number;
  answerData: string;
  problemLevel: number;
  problemType: string;
  problemURL: string;
  unitName: string;
  needCheckLayout: number;
  source: number;
  hide: number;
  curriculumNumber: number;
  cebuCode: number;
  totalTimes: number;
  correctTimes: number;
  hwpExist: number;
  scorable: number;
  tagTop: null;
  bookDataId: number;
};

function App() {
  const [problemList, setProblemList, problemListRef] = useStateWithLatest<ProblemType[]>([]);
  const [similarList, setSimilarList, similarListRef] = useStateWithLatest<ProblemType[]>([]);
  const [activatedId, setActivatedId, activatedIdRef] = useStateWithLatest(-1);
  const [activatedUnitName, setActivatedUnitName] = useState("");

  /**
   *  Functions for problem list
   */
  const getProblemList = () => {
    setProblemList(problems.data);
  }

  const getSimilarList = () => {
    setSimilarList(similars.data);
  }

  const initActive = () => {
    setActivatedId(-1);
    setSimilarList([]);
    setActivatedUnitName("");
  }
  const getSimilar = useCallback((id: number) => {
    if (activatedIdRef.current === id) {
      initActive();
    } else {
      getSimilarList();
      setActivatedId(id);
      setActivatedUnitName(problemListRef.current[problemListRef.current.findIndex(_ => _.id === id)].unitName);
    }
  }, [])

  const deleteProblem = useCallback((id: number) => {
    const newList = problemListRef.current.filter(_ => _.id !== id);
    setProblemList(newList);
    if (activatedIdRef.current === id) {
      initActive();
    }
  }, [])



  /**
   * Functions for Similar problem list 
   */

  const checkDuplicated = (id: number) => {
    return problemListRef.current.some(_ => _.id === id);
  }

  const passToProblemListFromSimilarList = (id: number, targetId: number, isReplace: boolean) => {
    if (checkDuplicated(id)) {
      alert('이미 존재하는 문항입니다.');
      return;
    }

    const newProblemList = [...problemListRef.current];
    const targetIndex = newProblemList.findIndex(_ => _.id === targetId);

    const similarIndex = similarListRef.current.findIndex(_ => _.id === id);
    const startIndex = isReplace ? targetIndex : targetIndex + 1;
    const deleteCount = isReplace ? 1 : 0;
    newProblemList.splice(startIndex, deleteCount, similarListRef.current[similarIndex]);

    setProblemList(newProblemList);

    const newSimilarList = similarListRef.current.filter(_ => _.id !== id);
    setSimilarList(newSimilarList);

    if (isReplace) {
      setActivatedId(id);
    }
  }

  const addProblem = useCallback((id: number, targetId: number) => {
    passToProblemListFromSimilarList(id, targetId, false);
  }, [])

  const replaceProblem = useCallback((id: number, targetId: number) => {
    passToProblemListFromSimilarList(id, targetId, true);
  }, [])

  /**
   * Effect
   */
  useEffect(() => {
    getProblemList();
  }, [])

  return (
    <AppBlock>
      {/* Problem List SEction */}
      <section>
        <header><h3>학습지 상세 편집</h3></header>
        <ListWrapper>
          <ProblemList
            problems={problemList}
            activatedId={activatedId}
            getSimilar={getSimilar}
            deleteProblem={deleteProblem}
          />
        </ListWrapper>
      </section>

      {/* Similar Problem List SEction */}
      <section>
        <header><h3 style={{ textAlign: 'center' }}>문항 교체/추가</h3></header>
        <ListWrapper>
          {similarList.length
            ?
            <>
              <SimilarUnitName>{activatedUnitName}</SimilarUnitName>
              <SimliarList
                problems={similarList}
                targetId={activatedId}
                addProblem={addProblem}
                replaceProblem={replaceProblem}
              />
            </>

            :
            <SimilarListPlaceHolder>
              <p><span>유사문항</span> 버튼을 누르면<br />해당 문제의 유사 문항을 볼 수 있습니다.</p>
            </SimilarListPlaceHolder>
          }
        </ListWrapper>
      </section>
    </AppBlock>

  );
}

const AppBlock = styled.div`
  display: flex;
  justify-content: space-between;

  >section {
    width: calc(50% - 3px);
    height: 500px;
    background: #F5F5F5;

    >header {
      
      height: 46px;
      background: white;
      margin-bottom: 3px;
      >h3 {
        line-height: 46px;
        margin-left: 24px;
      }
    }
  }
`;

const SimilarUnitName = styled.p`
  padding-top: 8px;
  padding-bottom: 8px;
  padding-left: 25px;
`;

const SimilarListPlaceHolder = styled.div`
  background: white;
  height: calc(100vh - 48px - 3px);
  display: flex;
  justify-content: center;
  align-items: center;
  >p {
    text-align: center;
    line-height: 28px;
    >span {
      font-size: 12px;
      font-weight: 700;
      padding: 5px 12.5px 5px 12.5px;
      color: #00ABFF;
      border: 1px solid #E0E0E0;
      border-radius: 2px;
    }
  }
`;

const ListWrapper = styled.div`
    height: calc(100vh - 48px - 3px);
    overflow-y: auto;
`;

export default App;
