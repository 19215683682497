import React, { useCallback, useRef, useState } from "react";

export default function useStateWithLatest<T = any>(defaultValue: T) {
    const [state, setState] = useState<T>(defaultValue);
    const ref = useRef<any>(state);

    const setter:React.Dispatch<React.SetStateAction<T>> = useCallback((value) => {
        ref.current = value;
        setState(value);
    }, [])

    return [state, setter, ref] as [T, React.Dispatch<React.SetStateAction<T>>, React.MutableRefObject<T>]
}