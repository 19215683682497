import React from 'react';
import styled, { css } from 'styled-components';

type Props = {
    index: number;
    id: number;
    problemType: string;
    unitName: string;
    problemURL: string;
    headerButtonGroupComponent: React.ReactElement;
}
const ProblemItem: React.FunctionComponent<Props> = ({
    index,
    id,
    problemType,
    unitName,
    problemURL,
    headerButtonGroupComponent
}) => {
    return (
        <ProblemItemBlock key={id}>
            <header>
                <div className="type__name">
                    <ProblemType>{problemType}</ProblemType>
                    <UnitName>{unitName}</UnitName>
                </div>

                {headerButtonGroupComponent}
            </header>
            <ItemBody>
                <h3>{index + 1}</h3>
                <img src={problemURL} alt="problem_image" />
            </ItemBody>
        </ProblemItemBlock>
    )
}

const ProblemItemBlock = styled.div`
  background: white;
  width: 100%;
  margin-bottom: 8px;

  >header {
    border-bottom: 1px solid #F5F5F5;
    display: flex;
    justify-content: space-between;
    height: 53px;
    align-items: center;
    >.type__name {
        display: flex;
    }
  }
`;

const ProblemType = styled.p`
  width: 100px;
  padding-right: 18px;
  text-align: right;
`;

const UnitName = styled.p`
    width: calc(50vw - 100px - 194px);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
`;

const ItemBody = styled.div`
  display: flex;
  padding-top: 18px;
  padding-bottom: 23px;
  >h3 {
        width: 100px;
        padding-right: 37px;
        text-align: right;
        color: #02C7F2;
        font-size: 24px;
        font-weight: 700;
  }

  >img {
      /* max-width: 100%;
      object-fit: cover; */
  }
`;

export default ProblemItem;