import React, { useCallback } from 'react';
import styled, { css } from 'styled-components';
import ProblemItem from './ProblemItem';

type Props = {
    problems: {
        id: number;
        unitCode: number;
        answerData: string;
        problemLevel: number;
        problemType: string;
        problemURL: string;
        unitName: string;
        needCheckLayout: number;
        source: number;
        hide: number;
        curriculumNumber: number;
        cebuCode: number;
        totalTimes: number;
        correctTimes: number;
        hwpExist: number;
        scorable: number;
        tagTop: null;
        bookDataId: number;
    }[];
    activatedId: number;
    getSimilar: (activatedId: number) => void;
    deleteProblem: (activatedId: number) => void;
}
type ButtonGroupProps = {
    id: number;
    activatedId: number;
    getSimilar: (activatedId: number) => void;
    deleteProblem: (activatedId: number) => void;
}

const ButtonGroup: React.FunctionComponent<ButtonGroupProps> = ({ id, activatedId, getSimilar, deleteProblem }) => {
    const handleGetSimilar = useCallback(() => {
        getSimilar(id);
    }, [])

    const handleDeleteProblem = useCallback(() => {
        deleteProblem(id);
    }, [])

    return (
        <ButtonGroupBlock>
            <Button active={id === activatedId} onClick={handleGetSimilar}>유사문항</Button>
            <Button onClick={handleDeleteProblem}>삭제</Button>
        </ButtonGroupBlock>
    )
}

const ProblemList: React.FunctionComponent<Props> = ({ problems, activatedId, getSimilar, deleteProblem }) => {
    return (
        <>
            {problems.map((item, index) => {
                return (
                    <ProblemItem
                        key={item.id}
                        index={index}
                        id={item.id}
                        problemType={item.problemType}
                        unitName={item.unitName}
                        problemURL={item.problemURL}
                        headerButtonGroupComponent={
                            <ButtonGroup
                                id={item.id}
                                activatedId={activatedId}
                                getSimilar={getSimilar}
                                deleteProblem={deleteProblem}
                            />
                        }
                    />
                )
            })}
        </>
    )
}


const ButtonGroupBlock = styled.div`
    display: flex;
    margin-right: 15px;
    >:first-child {
        margin-right: 8px;
    }
`;

const Button = styled.button<{ active?: boolean }>`
  width: 80px;
  height: 36px;
  font-size: 14px;
  font-weight: 700;
  color: #00ABFF;
  border: 1px solid #E0E0E0;
  border-radius: 3px;
  background: white;

  ${props => props.active &&
        css`
            border-color: #00ABFF;
            background: #00ABFF;
            color: white;
        `
    }
`;


export default React.memo(ProblemList);